module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#252F3F","theme_color":"#252F3F","display":"minimal-ui","icon":"static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e16a4b1a90d0ff8927eef90fce1d01e8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"remarkPlugins":[null],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/app","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-posthog/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"jy46Q76-Z2XpQunymYdEDk-w6FhAhWIcWsElw6WYPNA","apiHost":"https://posthog.ethibox.fr","head":true,"isEnabledDevMode":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
