exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cgu-js": () => import("./../../../src/pages/cgu.js" /* webpackChunkName: "component---src-pages-cgu-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-free-js": () => import("./../../../src/pages/free.js" /* webpackChunkName: "component---src-pages-free-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legals-js": () => import("./../../../src/pages/legals.js" /* webpackChunkName: "component---src-pages-legals-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-templates-app-template-2-js": () => import("./../../../src/templates/appTemplate2.js" /* webpackChunkName: "component---src-templates-app-template-2-js" */),
  "component---src-templates-app-template-js": () => import("./../../../src/templates/appTemplate.js" /* webpackChunkName: "component---src-templates-app-template-js" */),
  "component---src-templates-apps-template-js": () => import("./../../../src/templates/appsTemplate.js" /* webpackChunkName: "component---src-templates-apps-template-js" */),
  "component---src-templates-transparence-template-js": () => import("./../../../src/templates/transparenceTemplate.js" /* webpackChunkName: "component---src-templates-transparence-template-js" */)
}

